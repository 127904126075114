.body {
  overflow: scroll;
  margin: auto;
}

.table {
  $border-color: #c8c8c8;
  $background-color: #f0f0f0;
  $text-color: #5a5a5a;

  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0px !important;

  th,
  td {
    vertical-align: middle;
    border: 1px solid $border-color;
    padding: 10px;
    color: $text-color;
    font-size: 1rem;
  }
  th {
    text-align: center;
    background-color: $background-color;
    font-weight: normal;
    // Tabletは改行しない
    @media (max-width: 768px) {
      white-space: nowrap;
    }
  }
  td {
    text-align: left;
    // Tabletは改行しない
    @media (max-width: 768px) {
      white-space: pre-line;
    }
  }
}

.caution {
  white-space: pre-line;
}
