.optionTitle {
  // PC
  @media (min-width: 1036px) {
    white-space: nowrap;
  }
  // SP
  @media (max-width: 1035px) {
    white-space: pre-line;
  }
}
