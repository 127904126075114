.root {
  width: 100%;
  background-color: #78b428;
  position: relative;
  padding: 20px 28px;
  gap: 20px;
  // PC
  @media (min-width: 1036px) {
    border-radius: 4px;
  }
}

.title {
  width: 100%;
}

.lightGreen {
  color: #78b428;
}

.body {
  width: 100%;
  padding: 20px 28px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
}

$trigger-button-height: 24px;

.openButton {
  position: absolute;
  background-color: #f0f0f0;
  height: $trigger-button-height;
  padding: 0 10px;
  bottom: calc(-1 * $trigger-button-height / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #c8c8c8;
  font-weight: bold;
  > i {
    margin-left: 4px;
  }
}

.bodySection {
  width: 100%;
}
