.body {
  overflow: scroll;
  height: 80vh;
  margin: auto;
}
.modal {
  // ボトムアクションよりも上に表示するため
  z-index: 10001 !important;
}

.threeMonthDiscountImage {
  object-fit: contain;
  height: auto;
  width: 100%;
  max-width: 360px;
}

.header {
  background-color: #78b428;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1.2em;
  position: absolute;
  top: -20px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.benefitItem {
  color: #333;
  font-size: 1.1em;
  line-height: 1.4;
}

.checkIcon {
  margin-right: 10px;
}

.note {
  font-size: 0.8em;
  color: #666;
}

.table {
  $border-color: #c8c8c8;
  $background-color: #f0f0f0;
  $text-color: #5a5a5a;

  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0px !important;

  th,
  td {
    vertical-align: middle;
    border: 1px solid $border-color;
    padding: 18px 10px;
    color: $text-color;
    font-size: 0.75rem;
  }
  th {
    text-align: center;
    white-space: pre;
  }
  td {
    text-align: left;
  }

  thead {
    th {
      background-color: $background-color;
      font-weight: bold;
    }
  }
}

.caution {
  white-space: pre-line;
}
