.root {
  position: absolute;
  display: flex;
}
.modal {
  z-index: -9999;
  opacity: 0;
}
.modalActive {
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s linear 0s,
    opacity 0.3s ease;
}

.modalInactive {
  z-index: -9999;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0.3s,
    opacity 0.3s ease;
}

.modalMask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modalContainer {
  position: relative;
  width: 640px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    margin: auto;
  }
}

.maxHeightWithoutCloseIcon {
  max-height: calc(100vh - 40px);
  max-height: calc(-webkit-fill-available - 40px); // 古いiOS用
  max-height: calc(100dvh - 40px); // 新しいiOS用
}

.maxHeightWithCloseIcon {
  max-height: calc(100vh - 80px);
  max-height: calc(-webkit-fill-available - 80px); // 古いiOS用
  max-height: calc(100dvh - 80px); // 新しいiOS用
  top: 20px;
}

.modalClose {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -50px;
  right: 0;
  cursor: pointer;
}

.modalHeader {
  flex: 0 0 auto;
  width: 100%;
}

.modalBody {
  padding: 20px;
  flex: 1 1 auto;
  overflow: auto;
}

.modalFooter {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-width: 1px 0 0 0;
  padding: 20px;
  border-top: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}
