.iconContainer {
  background-color: #78b428;
  padding: 4px;
  border-radius: 1000px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
