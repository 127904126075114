.root {
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
}
.header {
  white-space: pre-line;
}

.footer {
  line-height: 1.25;
}

.hr {
  width: 100%;
}

.price {
  font-size: 2.5rem;
  display: flex;
  gap: 5px;
  align-items: baseline;
  justify-content: center;
}

.warning {
  border: 1px solid #ff6478;
  padding: 10px;
}

.lightGreen {
  color: #78b428;
}

.focusText {
  color: #78b428;
  font-size: 1.5rem;
  margin-right: 4px;
  margin-left: 4px;
}
