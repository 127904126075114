.icon {
  cursor: pointer;
  margin-left: 4px;
}

.tooltip {
  white-space: pre-wrap;
  z-index: 99999 !important;
  background-color: #707070 !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  padding: 10px !important;
  white-space: pre-line;
}
